<template>
  <div>
    <!-- Exhibitors -->
    <b-row align-h="between">
      <b-col><h2>{{exhibitionTitle}}</h2></b-col>
    </b-row>
    <b-tabs pills>
      <b-tab v-for="category in Object.keys(exhibitors)" :key="category" :title="category">
        <b-row class="match-height">
          <b-col xs="12" md="6" v-for="exhibitor in exhibitors[category]" :key="exhibitor.id">
            <exbibitor-card :data="exhibitor" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <!-- Exhibitors End-->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BTabs,
} from "bootstrap-vue"
import ExbibitorCard from "./ExhibitorCard.vue"

export default {
  metaInfo() {
    return {
      title: this.exhibitionTitle
    }
  },
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    ExbibitorCard,
  },
  data() {
    return {
      exhibitionTitle: store.getters['navigation/getTitle']('exhibition'),
    };
  },
  computed: {
    exhibitors() {
      return this.$store.getters['exhibitors/allCategorized']
    }
  },
  mounted() {
    // console.log('sessions', this.$store.state.sessions);
    // if (!this.$store.state.sessions.sessions || this.$store.state.sessions.sessions.length === 0) {
      this.$store.dispatch('exhibitors/getExhibitors', '')
    // } 
  },
};
</script>

<style>
</style>
