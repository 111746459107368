<template>
  <b-link :href="'/exhibition/' + data.slug" class="rounded-lg shadow-lg mb-2">
    <b-card
      v-if="data"
      no-body
      class="booth rounded-lg shadow-lg mb-0"
    >
      <b-card-img-lazy :src="data.banner" :alt="data.name" class="rounded-lg" />
      <b-card-body overlay class="rounded-lg">
        <div class="rounded-lg h-100 d-flex flex-column justify-content-between">
          <b-img 
            :src="data.logo" 
            :alt="data.name" 
            thumbnail 
            width="100px"
            height="auto"
            left
            class="rounded-lg bg-white border-primary"
            style="z-index:100" 
          />
          <div class="d-flex flex-column">
            <span class="h2 font-weight-bolder" style="z-index:100" v-bind:class="data.textClass">{{data.name}}</span>
            <span class="h5 font-weight-bolder" style="z-index:100" v-bind:class="data.textClass" >{{data.tagline}}</span>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-link>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BCardImgLazy, BLink, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BCardImgLazy,
    BLink,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>

.booth::after {
  background-image: linear-gradient(180deg, rgba(0,0,0,0.07) 70%, rgba(0,0,0,0.66) 90%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.badge-lg.badge-floating-end {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .badge-floating.badge-floating-end {
    left: auto !important;
    right: 0;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .badge-lg {
    padding-top: 0.625rem;
    padding-right: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .badge-floating {
    position: absolute;
    top: 1.5rem;
    left: 0;
    z-index: 5;
  }
  .badge {
    color: #fff !important;
  }
  .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
  .badge {
    display: inline-block;
    padding: 0.25em 0.5em;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
  }
</style>
